import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import Inventory from '../components/Inventory';
import Stash from '../components/Stash';
import { useImageCache } from '../components/ImageCacheContext';
import { formatItemStyle, formatNumber, generateFixedTooltip, showNotification } from '../components/Utils';
import { useMediaQuery } from 'react-responsive';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/tooltip';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/tooltip.css';
import ItemSelect from '../components/ItemSelect';
import config from '../config';

const AddListing = () => {
  const [name, setName] = useState("");
  const [amount, setAmount] = useState(1);
  const [maximumAmount, setMaximumAmount] = useState(0);
  const [price, setRawPrice] = useState(0);
  const [minimumPrice, setMinimumPrice] = useState(0);
  const [inventory, setInventory] = useState([]);
  const [stash, setStash] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeInventoryIndex, setActiveInventoryIndex] = useState(null);
  const [activeStashIndex, setActiveStashIndex] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [marketFee, setMarketFee] = useState(0);
  const navigate = useNavigate();
  const { loadedImages, preloadImages } = useImageCache();
  const [submitting, setSubmitting] = useState(false);
  const [itemStyle, setItemStyle] = useState({});
  const priceInputRef = useRef(null);
  const itemSelectRef = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const setPrice = (newPrice) => {
    const numericValue = parseFloat(newPrice.toString().replace(/,/g, ''));
    if (!isNaN(numericValue)) {
      setRawPrice(numericValue);
    }
  };

  const optionMapping = useRef({}); // To store the mapping between options and indices

  useEffect(() => {
    const fetchData = async () => {
      const url = `${config.serverURL}/getinventory`;
      const body = {
        jwt: null,
        accessToken: localStorage.getItem("twitchToken")
      };
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body)
        });
        if (response.ok) {
          const data = await response.json();
          const tempInventory = data.user.inventory.map(item => ({
            ...item,
            tradable: item.tradable === undefined && item.gem === undefined ? true : item.tradable
          }));
          const tempStash = data.user.stash.map(item => ({
            ...item,
            tradable: item.tradable === undefined && item.gem === undefined ? true : item.tradable
          }));
          setInventory(tempInventory);
          setStash(tempStash);
          preloadImages([...tempInventory, ...tempStash]);
          setLoading(false);
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
    $(document).tooltip({
      track: true
    });
    //const intervalId = setInterval(fetchData, 5000);
    return () => {
      //clearInterval(intervalId);
      $(document).tooltip('destroy');
    };
  }, []);

  useEffect(() => {
    if (inventory.length > 0 || stash.length > 0) {
      updateSelectedItem(inventory, stash);
    }
  }, [inventory, stash]);

  const updateSelectedItem = (newInventory, newStash) => {
    if (activeItem) {
      const itemExistsInInventory = newInventory.some(item => compareItems(item, activeItem));
      const itemExistsInStash = newStash.some(item => compareItems(item, activeItem));
      if (itemExistsInInventory) {
        const index = newInventory.findIndex(item => compareItems(item, activeItem));
        setActiveInventoryIndex(index);
        setActiveStashIndex(null);
        setActiveItem(newInventory[index]);
        setItemStyle(formatItemStyle(newInventory[index], loadedImages, true));
      } else if (itemExistsInStash) {
        const index = newStash.findIndex(item => compareItems(item, activeItem));
        setActiveStashIndex(index);
        setActiveInventoryIndex(null);
        setActiveItem(newStash[index]);
        setItemStyle(formatItemStyle(newStash[index], loadedImages, true));
      } else {
        clearSelection();
      }
    } else {
      clearSelection();
    }
  };

  const compareItems = (item1, item2) => {
    return item1.name === item2.name && item1.damage === item2.damage && item1.armor === item2.armor && item1.goldValue === item2.goldValue && item1.bonusDamage == item2.bonusDamage && item2.bonusArmor == item2.bonusArmor && item1.shimmering == item2.shimmering;
  };

  const clearSelection = () => {
    setActiveItem(null);
    setItemStyle({});
    setName("");
    setAmount(1);
    setMaximumAmount(1);
    setPrice(0);
    setMinimumPrice(0);
    setActiveInventoryIndex(null);
    setActiveStashIndex(null);
  };

  const handleInventoryItemSelect = (item, index) => {
    if (!item.tradable) {
      showNotification("This item is not tradable and cannot be selected.");
      return;
    }
    setName(item.name);
    setAmount(item.amount ?? 1);
    setMaximumAmount(item.amount ?? 1);
    setPrice(item.goldValue);
    setMinimumPrice(item.goldValue);
    setActiveInventoryIndex(index);
    setActiveStashIndex(null);
    setActiveItem(item);
    setItemStyle(formatItemStyle(item, loadedImages, true));
  };

  const handleStashItemSelect = (item, index) => {
    if (!item.tradable) {
      showNotification("This item is not tradable and cannot be selected.");
      return;
    }
    setName(item.name);
    setAmount(item.amount ?? 1);
    setMaximumAmount(item.amount ?? 1);
    setPrice(item.goldValue);
    setMinimumPrice(item.goldValue);
    setActiveStashIndex(index);
    setActiveInventoryIndex(null);
    setActiveItem(item);
    setItemStyle(formatItemStyle(item, loadedImages, true));
  };

  const createListing = async (item, price, amount) => {
    const url = `${config.serverURL}/createMarketOrder`;
    const accessToken = localStorage.getItem("twitchToken");

    delete item.tradable;

    const payload = {
      jwt: null,
      accessToken: accessToken,
      item: item,
      price: price,
      amount: amount
    };

    setSubmitting(true);
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });

      if (response.ok) {
        const responseData = await response.json();
        showNotification(
          `You successfully listed ${amount > 1 ? `${amount} ${item.name} for ${price} gold each` : `${item.name} for ${price}`}`,
          () => {
            navigate(`/listing/${responseData.marketOrder.marketId}`);
          }
        );
      } else if (response.status === 404) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message);
      } else if (response.status === 403) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message);
      } else {
        throw new Error('Unknown Error');
      }

    } catch (error) {
      showNotification(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const numericPrice = parseFloat(price.toString().replace(/,/g, ''));
    if (numericPrice < minimumPrice) {
      showNotification(`The price must be at least ${formatNumber(minimumPrice)}`);
      setPrice(minimumPrice);
      return;
    }
    const numericAmount = parseFloat(amount.toString().replace(/,/g, ''));
    if (numericAmount > maximumAmount) {
      showNotification(`The amount can't be more than ${formatNumber(maximumAmount)}`);
      return;
    }
    if (numericAmount < 1) {
      showNotification(`The amount must be at least 1`);
      return;
    }
    if (activeItem) {
      createListing(activeItem, numericPrice, numericAmount);
    } else {
      showNotification("You need to select an item before adding a listing.");
    }
  };

  const handleItemSelect = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const type = selectedOption.getAttribute('data-type');
    const index = parseInt(selectedOption.getAttribute('data-index'), 10);

    if (type === 'inventory') {
      handleInventoryItemSelect(inventory[index], index);
    } else if (type === 'stash') {
      handleStashItemSelect(stash[index], index);
    }
  };

  useEffect(() => {
    if (isMobile && itemSelectRef.current && !itemSelectRef.current.initialized) {
      itemSelectRef.current.initialized = true;
      if (window.RPGUI) {
        window.RPGUI.create(itemSelectRef.current, "dropdown");
        itemSelectRef.current.className = '';

        const selectOptions = $(itemSelectRef.current).find('option');
        selectOptions.each((index, option) => {
          const type = $(option).data('type');
          const itemIndex = $(option).data('index');
          optionMapping.current[index] = { type, itemIndex };
        });
      }
    }

    const handleCustomDropdownClick = (e) => {
      const clickedIndex = $(e.currentTarget).index();
      const { type, itemIndex } = optionMapping.current[clickedIndex];
      if (type === 'inventory') {
        handleInventoryItemSelect(inventory[itemIndex], itemIndex);
      } else if (type === 'stash') {
        handleStashItemSelect(stash[itemIndex], itemIndex);
      }
    };

    $(document).on('click', '.rpgui-dropdown-imp li', handleCustomDropdownClick);

    return () => {
      $(document).off('click', '.rpgui-dropdown-imp li', handleCustomDropdownClick);
      $('.inventories p.rpgui-dropdown-imp.rpgui-dropdown-imp-header').remove();
      $('.inventories ul.rpgui-dropdown-imp').remove();
    };
  }, [isMobile, inventory, stash]);

  useEffect(() => {
    setMarketFee((price * amount * 0.08));
  }, [price, amount]);

  return (
    <div className="rpgui-container framed-golden add-listing-container centered">
      {loading ? (
        <p>Loading data...</p>
      ) : (
        <>
          {isMobile ? (
            <div className="inventory-stash">
              <div className="item-dropdown" style={{ display: isMobile ? 'block' : 'none' }}>
                <ItemSelect 
                  ref={itemSelectRef}
                  inventory={inventory}
                  stash={stash}
                  activeInventoryIndex={activeInventoryIndex}
                  activeStashIndex={activeStashIndex}
                  handleItemSelect={handleItemSelect}
                />
              </div>
              {activeItem && generateFixedTooltip({ item: activeItem, itemStyle })}
              <form className="add-listing-form" onSubmit={handleSubmit}>
                <div className="add-listing-inputs">
                  <label htmlFor="amount">Amount</label>
                  <NumericFormat
                    thousandSeparator={true}
                    value={amount}
                    onValueChange={(values) => setAmount(values.value)}
                    required
                  />
                  <label htmlFor="price">Price</label>
                  <NumericFormat
                    thousandSeparator={true}
                    value={price}
                    onValueChange={(values) => setPrice(values.value)}
                    getInputRef={priceInputRef}
                    required
                  />
                  <label htmlFor="total-price">Total Price</label>
                  <NumericFormat
                    thousandSeparator={true}
                    value={price * amount}
                    disabled
                  />
                  <label htmlFor="market-fee" title="Fee is not refundable.">Market Fee (8%)</label>
                  <NumericFormat
                    thousandSeparator={true}
                    value={marketFee}
                    disabled
                  />
                </div>
                <button className="rpgui-button" disabled={submitting}>
                  <p>Create</p>
                </button>
              </form>
            </div>
          ) : (
            <div className="inventories">
              <div className="inventory">
                <p>Inventory</p>
                <Inventory
                  inventory={inventory}
                  onItemSelect={handleInventoryItemSelect}
                  loadedImages={loadedImages}
                  isActive={activeInventoryIndex}
                />
                <form className="add-listing-form" onSubmit={handleSubmit}>
                  <div className="add-listing-inputs">
                    <label htmlFor="amount">Amount</label>
                    <NumericFormat
                      thousandSeparator={true}
                      value={amount}
                      onValueChange={(values) => setAmount(values.value)}
                      required
                    />
                    <label htmlFor="price">Price</label>
                    <NumericFormat
                      thousandSeparator={true}
                      value={price}
                      onValueChange={(values) => setPrice(values.value)}
                      getInputRef={priceInputRef}
                      required
                    />
                    <label htmlFor="total-price">Total Price</label>
                    <NumericFormat
                      thousandSeparator={true}
                      value={price * amount}
                      disabled
                    />
                    <label htmlFor="market-fee" title="Fee is not refundable.">Market Fee (8%)</label>
                    <NumericFormat
                      thousandSeparator={true}
                      value={marketFee}
                      disabled
                    />
                  </div>
                  <button className="rpgui-button" disabled={submitting}>
                    <p>Create</p>
                  </button>
                </form>
              </div>
              <div className="stash">
                <p>Stash</p>
                <Stash
                  stash={stash}
                  onItemSelect={handleStashItemSelect}
                  loadedImages={loadedImages}
                  isActive={activeStashIndex}
                />
                {activeItem && generateFixedTooltip({ item: activeItem, itemStyle })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AddListing;