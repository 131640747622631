import React from 'react';
import lockImage from '../images/inventory_background/lock.png';
import nonTradableImage from '../images/inventory_background/non-tradable.png';
import lockNonTradableImage from '../images/inventory_background/locknon-tradable.png';
import forbiddenArmorImage from '../images/inventory_background/forbidden-armor.png';
import forbiddenDamageImage from '../images/inventory_background/forbidden-damage.png';
import legendaryArmorImage from '../images/inventory_background/legendary-armor.png';
import legendaryDamageImage from '../images/inventory_background/legendary-damage.png';
import epicArmorImage from '../images/inventory_background/epic-armor.png';
import epicDamageImage from '../images/inventory_background/epic-damage.png';
import extraordinaryArmorImage from '../images/inventory_background/extraordinary-armor.png';
import extraordinaryDamageImage from '../images/inventory_background/extraordinary-damage.png';
import shimmeringImage from '../images/inventory_background/shimmering.png';
import { toast } from 'react-toastify';
import { setGlobalToastMessage } from './ToastContext';
import { isMobile, isTablet, isBrowser } from 'react-device-detect';
import config from '../config';

/**
 * Formats the item amount for display.
 * 
 * @param {number} amount - The amount to format.
 * @returns {JSX.Element} The formatted amount as a JSX element.
 */
export function formatItemAmount(amount) {
  if (amount <= 999) {
    return <div className="item-amount">{amount}</div>;
  } else {
    const thousands = Math.floor(amount / 1000);
    const remainder = amount % 1000;
    return (
      <div className="item-amount">
        <span style={{ color: '#FFD700' }}>{thousands}</span>
        <div>{remainder.toString().padStart(3, '0')}</div>
      </div>
    );
  }
}

export const formatItemStyle = (item, loadedImages, skipLockedModifier = false) => {
  const baseImageUrl = "https://onestreamrpg.com/extension/images/items/";
  const defaultImageUrl = `${baseImageUrl}default.png`;
  let itemImage;
  if (item.name !== 'empty') {
    const imageName = item.name.replace(/\s/g, '');
    if (item.shimmering === 1) {
      itemImage = loadedImages[imageName + "_shimmering"] || defaultImageUrl;
    } else {
      itemImage = loadedImages[imageName] || defaultImageUrl;
    }
  } else {
    itemImage = defaultImageUrl;
  }

  let backgroundImageUrls = `url('${itemImage}')`;
  if (item.gem) {
    const gemName = item.gem.name.replace(/\s+/g, ''); // Remove all spaces
    const gemImageUrl = `${baseImageUrl}${gemName}.gif`; // Use .gif extension
    backgroundImageUrls = `url('${itemImage}'), url('${gemImageUrl}')`; // Layer item image first, gem image second
  }

  if (!skipLockedModifier){
    if (item.lock && (!item.tradable || item.gem)) {
      backgroundImageUrls = `url('${lockNonTradableImage}'), ${backgroundImageUrls}`;
    } else {
      if (item.lock) {
        backgroundImageUrls = `url('${lockImage}'), ${backgroundImageUrls}`;
      }
      if (!item.tradable || item.gem) {
        backgroundImageUrls = `url('${nonTradableImage}'), ${backgroundImageUrls}`;
      }
    }
  }

  if (item.armorBonus >= 1.0){ //FORBIDDEN ARMOR
    backgroundImageUrls = `url('${forbiddenArmorImage}'), ${backgroundImageUrls}`;
  } else if (item.armorBonus >= 0.8) { //LEGENDARY ARMOR
    backgroundImageUrls = `url('${legendaryArmorImage}'), ${backgroundImageUrls}`;
  } else if (item.armorBonus >= 0.6) { //EPIC ARMOR
    backgroundImageUrls = `url('${epicArmorImage}'), ${backgroundImageUrls}`;
  } else if (item.armorBonus >= 0.4) { //EXTRAORDINARY ARMOR
    backgroundImageUrls = `url('${extraordinaryArmorImage}'), ${backgroundImageUrls}`;
  }

  if (item.damageBonus >= 1.0){ //FORBIDDEN DAMAGE
    backgroundImageUrls = `url('${forbiddenDamageImage}'), ${backgroundImageUrls}`;
  } else if (item.damageBonus >= 0.8) { //LEGENDARY DAMAGE
    backgroundImageUrls = `url('${legendaryDamageImage}'), ${backgroundImageUrls}`;
  } else if (item.damageBonus >= 0.6) { //EPIC DAMAGE
    backgroundImageUrls = `url('${epicDamageImage}'), ${backgroundImageUrls}`;
  } else if (item.damageBonus >= 0.4) { //EXTRAORDINARY DAMAGE
    backgroundImageUrls = `url('${extraordinaryDamageImage}'), ${backgroundImageUrls}`;
  }

  if (item.shimmering === 1){ //SHINY ITEM
    backgroundImageUrls = `url('${shimmeringImage}'), ${backgroundImageUrls}`;
  }

  return {
    backgroundImage: backgroundImageUrls,
    backgroundPosition: 'center center, center center',
    backgroundRepeat: 'no-repeat, repeat',
    backgroundSize: 'cover, contain'
  };
};

export const formatNumber = (num) => {
  if (num === null || num === undefined) {
    return "";  // Return an empty string or some default value if input is not valid
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function formatMoney(number) {
    return String(new Intl.NumberFormat('en-US', { maximumFractionDigits: 1, notation: "compact", compactDisplay: "short" }).format(number));
}

export function formatDate(date) {
  var dateObj = new Date(date);
  return dateObj.toLocaleString();
}

export function formatRarity(value){
  if (value >= 1.0){
    return "Forbidden";
  } else if (value >= 0.8){
    return "Legendary";
  } else if (value >= 0.6){
    return "Epic";
  } else if (value >= 0.4){
    return "Extraordinary";
  }
}

export function descriptionWithStats(item){
  if (item.description === undefined)
    item.description = "none";

  var statsDescription = item.description;
  var reqLevel = false;

  if (item.levelRequirement !== undefined){
    statsDescription += `<br><br>[REQUIRES LEVEL ${item.levelRequirement}]<br>`;
    reqLevel = true;
  }

  if (!reqLevel)
    statsDescription += '<br>';

  if (item.armorBonus >= 1.0){ //FORBIDDEN ARMOR
    statsDescription += '<br>FORBIDDEN ARMOR';
  } else if (item.armorBonus >= 0.8) { //LEGENDARY ARMOR
    statsDescription += '<br>LEGENDARY ARMOR';
  } else if (item.armorBonus >= 0.6) { //EPIC ARMOR
    statsDescription += '<br>EPIC ARMOR';
  } else if (item.armorBonus >= 0.4) { //EXTRAORDINARY ARMOR
    statsDescription += '<br>EXTRAORDINARY ARMOR';
  }

  if (item.damageBonus >= 1.0){ //FORBIDDEN DAMAGE
    statsDescription += '<br>FORBIDDEN DAMAGE';
  } else if (item.damageBonus >= 0.8) { //LEGENDARY DAMAGE
    statsDescription += '<br>LEGENDARY DAMAGE';
  } else if (item.damageBonus >= 0.6) { //EPIC DAMAGE
    statsDescription += '<br>EPIC DAMAGE';
  } else if (item.damageBonus >= 0.4) { //EXTRAORDINARY DAMAGE
    statsDescription += '<br>EXTRAORDINARY DAMAGE';
  }

  return statsDescription;
}

export function formatDescription(item) {

  // Define the words and their corresponding colors, ensuring longer phrases come first
  const wordsToColor = [
      { word: 'extraordinary', color: config.extraordinaryColor },
      { word: 'epic', color: config.epicColor },
      { word: 'legendary', color: config.legendaryColor },
      { word: 'forbidden', color: config.forbiddenColor },
      { word: 'mana', color: 'lightblue' },
      { word: 'health', color: 'red' },
      { word: 'damages', color: '#ec8d34' },
      { word: 'damage', color: '#ec8d34' },
      { word: 'armor', color: '#20985d' },
      { word: 'one-hit', color: '#5C4033' }
  ];

  var description = descriptionWithStats(item);
  // Iterate over the words to color and replace them in the description
  wordsToColor.forEach(({ word, color }) => {
      const regex = new RegExp(word, 'gi'); // Create a case-insensitive regex
      description = description.replace(regex, match => `<span style="color: ${color};">${match}</span>`);
  });

  return description;
}

function styleObjectToCssString(styleObject) {
  return Object.entries(styleObject)
    .map(([key, value]) => {
      const cssKey = key.replace(/([A-Z])/g, '-$1').toLowerCase();
      return `${cssKey}: ${value}`;
    })
    .join('; ');
}

export function formatGem(gem) {
    if (gem !== undefined) {
        try {
            const gemPercentage = parseInt(gem.description.match(/\d+/)[0]);
            return gem.name + " (+" + gemPercentage + "%)";
        } catch (error) {
            return gem.description;
        }
    } else {
        return "";
    }
}

export function generateFixedTooltip({ item, itemStyle, extraClass="" }) {
  return (
    <div role="tooltip" className={`fixed-tooltip ${extraClass}`}>
      <div className="fixed-tooltip-content">
        <div className="item-image" style={itemStyle}></div>
        <span style={{ fontSize: '0.47vw' }}>{item.name}</span><br />
        <div style={{ marginTop: '0.26vw' }}></div>
        {item.damage > 0 && (
          <>
            {item.gem !== undefined ? (
              <div>
                Base Damage: {formatNumber(item.damage)}<br />
                Damage: {formatNumber(Math.round(item.damage * (1 + (item.gem?.gemRank ?? 0) * 0.08)))}<br />
              </div>
            ) : (
              <div>
                Damage: {formatNumber(Math.round(item.damage * (1 + (item.gem?.gemRank ?? 0) * 0.08)))}<br />
              </div>
            )}
          </>
        )}
        {item.armor > 0 && (
          <>
            {item.gem !== undefined ? (
              <div>
                Base Armor: {formatNumber(item.armor)}<br />
                Armor: {formatNumber(Math.round(item.armor * (1 + (item.gem?.gemRank ?? 0) * 0.08)))}<br />
              </div>
            ) : (
              <div>
                Armor: {formatNumber(Math.round(item.armor * (1 + (item.gem?.gemRank ?? 0) * 0.08)))}<br />
              </div>
            )}
          </>
        )}
        <div>Kind: {item.kind}</div>
        <div>Gold Value: {formatMoney(item.goldValue)}</div>
        {item.critChance && <div>Crit Chance: {item.critChance}%</div>}
        {item.critChance && <div>Crit Effectiveness: {item.critEffectiveness}%</div>}
        <br />
        <div dangerouslySetInnerHTML={{ __html: `${formatDescription(item)}` }}></div>
        {item.gem && <div><br />Gem: {formatGem(item.gem)}</div>}
      </div>
    </div>
  );
}

export function generateItemTooltip(item, loadedImages) {
  if (!item) return '';
  
  // Convert style object to CSS string
  const itemStyle = styleObjectToCssString(formatItemStyle(item, loadedImages, true));
  
  var itemTooltip =
      `<div class="item-image" style="${itemStyle}"></div>` +
      `<span style="font-size: 0.6vw;">${item.name}</span><br>` +
      `<div style="margin-top:0.26vw;"></div>`;
  
  if (item.damage > 0) {
      if (item.gem !== undefined){
        itemTooltip += `Base Damage: ${formatNumber(item.damage)}<br>`;
        itemTooltip += `Damage: ${formatNumber(Math.round(item.damage * (1 + (item.gem?.gemRank ?? 0) * 0.08)))}<br>`;
      } else {
        itemTooltip += `Damage: ${formatNumber(Math.round(item.damage * (1 + (item.gem?.gemRank ?? 0) * 0.08)))}<br>`;
      }
  }
  if (item.armor > 0) {
      if (item.gem !== undefined){
          itemTooltip += `Base Armor: ${formatNumber(item.armor)}<br>`;
          itemTooltip += `Armor: ${formatNumber(Math.round(item.armor * (1 + (item.gem?.gemRank ?? 0) * 0.08)))}<br>`;
      } else {
        itemTooltip += `Armor: ${formatNumber(Math.round(item.armor * (1 + (item.gem?.gemRank ?? 0) * 0.08)))}<br>`;
      }
  }

  itemTooltip +=
      `Kind: ${item.kind}<br>` +
      `Gold Value: ${formatMoney(item.goldValue)}<br>`;

  if (item.critChance){
    itemTooltip += `Crit Chance: ${item.critChance}<br>`;
    itemTooltip += `Crit Effectiveness: ${item.critEffectiveness}<br>`;
  }

  itemTooltip +=  
      `<br>${formatDescription(item)}` +
      `${item.gem ? `<br>Gem: ${formatGem(item.gem)}` : ''}`;

  return itemTooltip;
}

export const showNotification = (message, delayedCode) => {
  const toastPosition = isMobile || isTablet ? "bottom-center" : "bottom-right";
  
  if (delayedCode) {
    setGlobalToastMessage(message, delayedCode);
  } else {
    toast(message, {
      position: toastPosition,
    });
  }
};