import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import ActionButton from "../components/ActionButton";
import { generateItemTooltip, formatItemStyle, formatRarity, formatNumber } from '../components/Utils';
import { useImageCache } from '../components/ImageCacheContext';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/tooltip';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/tooltip.css';
import ResponsivePagination from 'react-responsive-pagination';
import { dropEllipsis } from 'react-responsive-pagination/narrowBehaviour';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { isMobile } from 'react-device-detect';
import config from '../config';
import FilterModal from '../components/FilterModal';
import { Helmet } from 'react-helmet';

const Listings = ({ me = false }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [isManualFetch, setIsManualFetch] = useState(false);
  const { loadedImages, preloadImages } = useImageCache();
  const [sortField, setSortField] = useState('timestamp');
  const [sortOrder, setSortOrder] = useState('desc');
  const [showModal, setShowModal] = useState(false);
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [kind, setKind] = useState('');
  const [shimmering, setShimmering] = useState('');
  const [minArmorBonus, setMinArmorBonus] = useState('');
  const [maxArmorBonus, setMaxArmorBonus] = useState('');
  const [minDamageBonus, setMinDamageBonus] = useState('');
  const [maxDamageBonus, setMaxDamageBonus] = useState('');
  const location = useLocation();
  const currentUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;
  const filterModalRef = useRef(null);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm !== debouncedSearchTerm) {
        setDebouncedSearchTerm(searchTerm);
      }
    }, 2000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    const page = searchParams.get('page') ? parseInt(searchParams.get('page'), 10) : 1;
    const search = searchParams.get('search') || '';
    const sortFieldParam = searchParams.get('sortField') || 'timestamp';
    const sortOrderParam = searchParams.get('sortOrder') || 'desc';
    const minPriceParam = searchParams.get('minPrice') || '';
    const maxPriceParam = searchParams.get('maxPrice') || '';
    const kindParam = searchParams.get('kind') || '';
    const shimmeringParam = searchParams.get('shimmering') || '';
    const minArmorBonusParam = searchParams.get('minArmorBonus') || '';
    const maxArmorBonusParam = searchParams.get('maxArmorBonus') || '';
    const minDamageBonusParam = searchParams.get('minDamageBonus') || '';
    const maxDamageBonusParam = searchParams.get('maxDamageBonus') || '';

    setCurrentPage(page);
    setSearchTerm(search);
    setDebouncedSearchTerm(search);
    setSortField(sortFieldParam);
    setSortOrder(sortOrderParam);
    setMinPrice(minPriceParam);
    setMaxPrice(maxPriceParam);
    setKind(kindParam);
    setShimmering(shimmeringParam);
    setMinArmorBonus(minArmorBonusParam);
    setMaxArmorBonus(maxArmorBonusParam);
    setMinDamageBonus(minDamageBonusParam);
    setMaxDamageBonus(maxDamageBonusParam);

    if (!isManualFetch) {
      fetchListings(search, page, sortFieldParam, sortOrderParam, minPriceParam, maxPriceParam, kindParam, shimmeringParam, minArmorBonusParam, maxArmorBonusParam, minDamageBonusParam, maxDamageBonusParam);
    }
    setIsManualFetch(false);

    filterModalRef.current?.setFilters({
      minPrice: minPriceParam,
      maxPrice: maxPriceParam,
      kind: kindParam,
      shimmering: shimmeringParam,
      minArmorBonus: minArmorBonusParam,
      maxArmorBonus: maxArmorBonusParam,
      minDamageBonus: minDamageBonusParam,
      maxDamageBonus: maxDamageBonusParam,
    });

  }, [searchParams, isManualFetch]);

  const fetchListings = (
    query = debouncedSearchTerm,
    page = currentPage,
    sortFieldParam = sortField,
    sortOrderParam = sortOrder,
    minPriceParam = minPrice,
    maxPriceParam = maxPrice,
    kindParam = kind,
    shimmeringParam = shimmering,
    minArmorBonusParam = minArmorBonus,
    maxArmorBonusParam = maxArmorBonus,
    minDamageBonusParam = minDamageBonus,
    maxDamageBonusParam = maxDamageBonus
  ) => {
    setLoading(true);
    const characterId = me ? `&characterId=${localStorage.getItem('characterId')}` : '';
    
    const urlParams = new URLSearchParams({
      page,
      limit: itemsPerPage,
      name: query,
      sortBy: sortFieldParam,
      order: sortOrderParam,
    });

    // Only append non-empty parameters
    if (minPriceParam) urlParams.append('minPrice', minPriceParam);
    if (maxPriceParam) urlParams.append('maxPrice', maxPriceParam);
    if (kindParam) urlParams.append('kind', kindParam);
    if (shimmeringParam) urlParams.append('shimmering', shimmeringParam);
    if (minArmorBonusParam) urlParams.append('minArmorBonus', minArmorBonusParam);
    if (maxArmorBonusParam) urlParams.append('maxArmorBonus', maxArmorBonusParam);
    if (minDamageBonusParam) urlParams.append('minDamageBonus', minDamageBonusParam);
    if (maxDamageBonusParam) urlParams.append('maxDamageBonus', maxDamageBonusParam);

    const url = `${config.serverURL}/marketListings?${urlParams.toString()}${characterId}`;
    const twitchData = JSON.parse(localStorage.getItem("twitchData"));
    const login = twitchData?.login;

    fetch(url)
      .then(res => res.json())
      .then(data => {
        preloadImages(data.listings.map(listing => listing.item));
        const listingsWithOwnerInfo = data.listings.map(listing => ({
          ...listing,
          isOwner: login === listing.username
        }));
        setListings(listingsWithOwnerInfo);
        setTotalPages(data.totalPages);
        setLoading(false);
      })
      .catch(error => {
        console.error('Failed to fetch listings:', error);
        setLoading(false);
      });
  };

  const updateUrlParams = (search, page, sortField, sortOrder, minPrice, maxPrice, kind, shimmering, minArmorBonus, maxArmorBonus, minDamageBonus, maxDamageBonus) => {
    const params = new URLSearchParams();

    if (search) params.set('search', search);
    if (page) params.set('page', page);
    if (sortField) params.set('sortField', sortField);
    if (sortOrder) params.set('sortOrder', sortOrder);
    if (minPrice) params.set('minPrice', minPrice);
    if (maxPrice) params.set('maxPrice', maxPrice);
    if (kind) params.set('kind', kind);
    if (shimmering) params.set('shimmering', shimmering);
    if (minArmorBonus) params.set('minArmorBonus', minArmorBonus);
    if (maxArmorBonus) params.set('maxArmorBonus', maxArmorBonus);
    if (minDamageBonus) params.set('minDamageBonus', minDamageBonus);
    if (maxDamageBonus) params.set('maxDamageBonus', maxDamageBonus);

    // Remove any empty parameters
    Array.from(params.entries()).forEach(([key, value]) => {
      if (value.trim() == '') {
        params.delete(key);
      }
    });

    // Update the URL with non-empty params
    navigate({
      pathname: location.pathname,
      search: params.toString()
    }, { replace: true });
  };

  const handleNextPage = () => {
    const newPage = currentPage + 1;
    setCurrentPage(newPage);
    updateUrlParams(debouncedSearchTerm, newPage, sortField, sortOrder, minPrice, maxPrice, kind, shimmering, minArmorBonus, maxArmorBonus, minDamageBonus, maxDamageBonus);
  };

  const handlePreviousPage = () => {
    const newPage = currentPage > 1 ? currentPage - 1 : 1;
    setCurrentPage(newPage);
    updateUrlParams(debouncedSearchTerm, newPage, sortField, sortOrder, minPrice, maxPrice, kind, shimmering, minArmorBonus, maxArmorBonus, minDamageBonus, maxDamageBonus);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    updateUrlParams(debouncedSearchTerm, page, sortField, sortOrder, minPrice, maxPrice, kind, shimmering, minArmorBonus, maxArmorBonus, minDamageBonus, maxDamageBonus);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setIsManualFetch(true);
    setCurrentPage(1);
    updateUrlParams(searchTerm, 1, sortField, sortOrder, minPrice, maxPrice, kind, shimmering, minArmorBonus, maxArmorBonus, minDamageBonus, maxDamageBonus);
    fetchListings(searchTerm, 1, sortField, sortOrder, minPrice, maxPrice, kind, shimmering, minArmorBonus, maxArmorBonus, minDamageBonus, maxDamageBonus);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setCurrentPage(1);
    setSortField('timestamp');
    setSortOrder('desc');
    setMinPrice('');
    setMaxPrice('');
    setKind('');
    setShimmering('');
    setMinArmorBonus('');
    setMaxArmorBonus('');
    setMinDamageBonus('');
    setMaxDamageBonus('');
    setIsManualFetch(true);
    filterModalRef.current.resetFilters();

    // Clear all URL parameters by navigating to the base path without any search parameters
    navigate(location.pathname, { replace: true });

    // Fetch listings with the default parameters
    fetchListings('', 1, 'timestamp', 'desc', '', '', '', '', '', '', '', '');
  };

  const handleInput = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      clearSearch();
    }
  };

  const handleSort = (field) => {
    const newSortOrder = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(newSortOrder);
    setCurrentPage(1); // Reset to the first page on sort change
    updateUrlParams(debouncedSearchTerm, 1, field, newSortOrder, minPrice, maxPrice, kind, shimmering, minArmorBonus, maxArmorBonus, minDamageBonus, maxDamageBonus);
    fetchListings(debouncedSearchTerm, 1, field, newSortOrder, minPrice, maxPrice, kind, shimmering, minArmorBonus, maxArmorBonus, minDamageBonus, maxDamageBonus);
  };

  const renderSortArrow = (field) => {
    if (sortField === field) {
      return sortOrder === 'asc' ? ' ↓' : ' ↑';
    }
    return '';
  };

  useEffect(() => {
    $(document).tooltip({
      track: true,
      content: function() {
        const element = $(this);
        const item = element.attr('title'); // Assuming data-item is set on the element
        if (item) {
          return item;
        }
        return '';
      }
    });
    return () => {
      $(document).tooltip('destroy');
    };
  }, []);

  const advancedSearch = () => {
    setShowModal(true);
  };

  const handleAdvancedSearch = (filters) => {
    setMinPrice(filters.minPrice);
    setMaxPrice(filters.maxPrice);
    setKind(filters.kind);
    setShimmering(filters.shimmering);
    setMinArmorBonus(filters.minArmorBonus);
    setMaxArmorBonus(filters.maxArmorBonus);
    setMinDamageBonus(filters.minDamageBonus);
    setMaxDamageBonus(filters.maxDamageBonus);
    setIsManualFetch(true);
    updateUrlParams(
      searchTerm,
      1,
      sortField,
      sortOrder,
      filters.minPrice,
      filters.maxPrice,
      filters.kind,
      filters.shimmering,
      filters.minArmorBonus,
      filters.maxArmorBonus,
      filters.minDamageBonus,
      filters.maxDamageBonus
    );
    fetchListings(
      searchTerm,
      1,
      sortField,
      sortOrder,
      filters.minPrice,
      filters.maxPrice,
      filters.kind,
      filters.shimmering,
      filters.minArmorBonus,
      filters.maxArmorBonus,
      filters.minDamageBonus,
      filters.maxDamageBonus
    );
  };

  const removeFilter = (filterType) => {
    const params = new URLSearchParams(searchParams.toString());

    // Remove the specific filter from params
    params.delete(filterType);

    if (filterType === 'armorBonus') {
      params.delete('minArmorBonus');
      params.delete('maxArmorBonus');
    }

    if (filterType === 'damageBonus') {
      params.delete('minDamageBonus');
      params.delete('maxDamageBonus');
    }

    // Ensure all other parameters are correctly maintained
    if (filterType !== 'search') {
      if (searchTerm) params.set('search', searchTerm);
      else params.delete('search');
    }
    if (filterType !== 'sortField') {
      if (sortField && sortField !== 'timestamp') params.set('sortField', sortField);
      else params.delete('sortField');
    }
    if (filterType !== 'sortOrder') {
      if (sortOrder && sortOrder !== 'desc') params.set('sortOrder', sortOrder);
      else params.delete('sortOrder');
    }
    if (filterType !== 'minPrice') {
      if (minPrice) params.set('minPrice', minPrice);
      else params.delete('minPrice');
    }
    if (filterType !== 'maxPrice') {
      if (maxPrice) params.set('maxPrice', maxPrice);
      else params.delete('maxPrice');
    }
    if (filterType !== 'kind') {
      if (kind) params.set('kind', kind);
      else params.delete('kind');
    }
    if (filterType !== 'shimmering') {
      if (shimmering) params.set('shimmering', shimmering);
      else params.delete('shimmering');
    }
    if (filterType !== 'armorBonus') {
      if (minArmorBonus) {
        params.set('minArmorBonus', minArmorBonus);
        params.set('maxArmorBonus', minArmorBonus);
      }
      else {
        params.delete('minArmorBonus');
        params.delete('maxArmorBonus');
      }
    }
    if (filterType !== 'damageBonus') {
      if (minDamageBonus) {
        params.set('minDamageBonus', minDamageBonus);
        params.set('maxDamageBonus', minDamageBonus);
      }
      else {
        params.delete('minDamageBonus');
        params.delete('maxDamageBonus');
      }
    }

    filterModalRef.current.resetSpecificFilter(filterType);

    // Update the URL with the modified params
    setSearchParams(params);

    // Fetch listings with the updated filters
    fetchListings(
      params.get('search') || '',
      parseInt(params.get('page'), 10) || 1,
      params.get('sortField') || 'timestamp',
      params.get('sortOrder') || 'desc',
      params.get('minPrice') || '',
      params.get('maxPrice') || '',
      params.get('kind') || '',
      params.get('shimmering') || '',
      params.get('minArmorBonus') || '',
      params.get('maxArmorBonus') || '',
      params.get('minDamageBonus') || '',
      params.get('maxDamageBonus') || ''
    );
  };

  return (
    <div className='table__container'>
      <Helmet>
        <title>Browse All Listings</title>
        <meta name="description" content="Browse All Listings." />
        <meta property="og:title" content="Browse All Listings" />
        <meta property="og:description" content="Browse All Listings" />
        <meta property="og:url" content={currentUrl} />
        <meta property="twitter:title" content="Browse All Listings" />
        <meta property="twitter:description" content="Browse All Listings" />
        <meta property="twitter:url" content={currentUrl} />
      </Helmet>
      <div className="add-listing-button">
        <button type="button" className="rpgui-button add-listing-button">
          <a href="/listings/add"><p>Add Listing</p></a>
        </button>
      </div>
      <form onSubmit={handleSearch} className="listing-form">
        <input 
          type="search" 
          placeholder="Search by name..." 
          value={searchTerm} 
          onChange={handleInput}
          onInput={handleInput}
        />
        <div className="button-container">
          <button type="submit" className="rpgui-button"><p>Search</p></button>
          <button type="button" className="rpgui-button" onClick={advancedSearch}><p>Advanced</p></button>
        </div>
      </form>
      <div className="current-filters">
        {(searchTerm || minPrice || maxPrice || kind || shimmering || minArmorBonus || maxArmorBonus || minDamageBonus || maxDamageBonus || (sortField && sortField !== 'timestamp') || (sortOrder && sortOrder !== 'desc')) && (
          <a href="#" onClick={clearSearch}>Clear Filters</a>
        )}
        {searchTerm && (
          <span>
            Search: {searchTerm} <button className="filter-button" onClick={() => removeFilter('search')}>x</button>
          </span>
        )}
        {minPrice && (
          <span>
            Min Price: {minPrice} <button className="filter-button" onClick={() => removeFilter('minPrice')}>x</button>
          </span>
        )}
        {maxPrice && (
          <span>
            Max Price: {maxPrice} <button className="filter-button" onClick={() => removeFilter('maxPrice')}>x</button>
          </span>
        )}
        {kind && (
          <span>
            Kind: {kind} <button className="filter-button" onClick={() => removeFilter('kind')}>x</button>
          </span>
        )}
        {shimmering && (
          <span>
            Shimmering: {shimmering} <button className="filter-button" onClick={() => removeFilter('shimmering')}>x</button>
          </span>
        )}
        {minArmorBonus && (
          <span>
            Armor Rarity: {formatRarity(minArmorBonus)} <button className="filter-button" onClick={() => removeFilter('armorBonus')}>x</button>
          </span>
        )}
        {minDamageBonus && (
          <span>
            Damage Rarity: {formatRarity(minDamageBonus)} <button className="filter-button" onClick={() => removeFilter('damageBonus')}>x</button>
          </span>
        )}
        {sortField && sortField !== 'timestamp' && (
          <span>
            Sort By: {sortField} <button className="filter-button" onClick={() => removeFilter('sortField')}>x</button>
          </span>
        )}
        {sortOrder && sortOrder !== 'desc' && (
          <span>
            Sort Order: {sortOrder} <button className="filter-button" onClick={() => removeFilter('sortOrder')}>x</button>
          </span>
        )}
      </div>      
      <div className="rpgui-container framed-golden listings-table-container">
        <Table>
          <Thead>
            <Tr>
              <Th>{isMobile ? "Picture" : ""}</Th>
              <Th onClick={() => handleSort('name')}>
                <a href="#" onClick={(e) => { e.preventDefault(); handleSort('name'); }}>
                  Title{renderSortArrow('name')}
                </a>
              </Th>
              <Th onClick={() => handleSort('amount')}>
                <a href="#" onClick={(e) => { e.preventDefault(); handleSort('amount'); }}>
                  Amount{renderSortArrow('amount')}
                </a>
              </Th>
              <Th onClick={() => handleSort('price')}>
                <a href="#" onClick={(e) => { e.preventDefault(); handleSort('price'); }}>
                  Price{renderSortArrow('price')}
                </a>
              </Th>
              <Th onClick={() => handleSort('creator')}>
                <a href="#" onClick={(e) => { e.preventDefault(); handleSort('creator'); }}>
                  Creator{renderSortArrow('creator')}
                </a>
              </Th>
              <Th>Buy</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading ? <Tr><Td colSpan="6">Loading...</Td></Tr> : listings.map(listing => (
              <Tr key={listing.marketId}>
                <Td title={generateItemTooltip(listing.item, loadedImages)}>
                  <div className="listing-item-image" style={formatItemStyle(listing.item, loadedImages, true)}></div>
                </Td>
                <Td>
                  <div className="osrpg-responsive-content"><a href={`/listing/${listing.marketId}`}>{listing.item.name}</a></div>
                </Td>
                <Td><div className="osrpg-responsive-content">{formatNumber(listing.amount)}</div></Td>
                <Td><div className="osrpg-responsive-content">{formatNumber(listing.price)}</div></Td>
                <Td><div className="osrpg-responsive-content">{listing.username}</div></Td>
                <Td><div style={{ overflowWrap: 'inherit' }}><ActionButton listing={listing} amount={listing.amount}/></div></Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
      <ResponsivePagination
        total={totalPages}
        current={currentPage}
        onPageChange={handlePageChange}
        narrowBehaviour={dropEllipsis}
        pageLinkClassName="osrpg-page-link"
      />
      <FilterModal ref={filterModalRef} show={showModal} onClose={() => setShowModal(false)} onSearch={handleAdvancedSearch} />
    </div>
  );
}

export default Listings;