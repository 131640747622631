import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { showNotification } from '../components/Utils';
import config from '../config';

const Home = () => {
    const [userName, setUserName] = useState("");
    const navigate = useNavigate();

    function fetchCharacterId(token) {
        return new Promise((resolve, reject) => {
            const body = {
                jwt: null,
                accessToken: token
            };

            fetch(`${config.serverURL}/getinventory`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })  
            .then(response => response.json())
            .then(json => {
                try {
                    const characterId = json.user.metaData.characterId;
                    if (characterId) {
                        localStorage.setItem("characterId", characterId);
                        resolve();
                    } else {
                        reject();
                    }
                } catch (e) {
                    console.error(e);
                    reject();
                }
            })
            .catch(error => {
                console.error(error);
                reject();
            });
        });
    }

    function saveTwitchData(token) {
        return new Promise((resolve, reject) => {
            const headers = {
                'client-id': '2cmepj3fwk1eo2ptx15w4n7kx0mb18',
                'Authorization': 'Bearer ' + token
            };

            fetch(
                'https://api.twitch.tv/helix/users',
                { method: 'GET', headers: headers }
            ).then(response => response.json())
            .then(json => {
                try {
                    const twitchData = json.data[0];

                    if (twitchData) {
                        fetchCharacterId(token).then(() => {
                            localStorage.setItem("twitchToken", token);
                            localStorage.setItem("twitchData", JSON.stringify(twitchData));
                            resolve();
                        }).catch(() => {
                            reject('Error fetching characterId... Grrr Swompy!');
                        });
                    } else {
                        reject('Error fetching Twitch Data');
                    }                    
                } catch (e) {
                    console.error(e);
                    reject('General Error');
                }
            })
            .catch(error => {
                console.error(error);
                reject();
            });
        });
    }

    useEffect(() => {
        if (localStorage.getItem("twitchToken") && localStorage.getItem("twitchData")) {
            navigate("/listings");
        } else {
            if (window.location.hash) {
                var tokenhash = window.location.hash.substr(1),
                access_token = tokenhash.substr(tokenhash.search(/(?<=^|&)access_token=/))
                              .split('&')[0]
                              .split('=')[1];

                if (access_token) {
                    saveTwitchData(access_token).then(() => {
                        navigate("/listings");
                    })
                    .catch((error) => {
                        showNotification(error);
                    });
                }
            }
        }            
    }, [navigate]);

    const redirectUri = process.env.NODE_ENV === 'development' 
    ? 'https://testmarket.onestreamrpg.com' 
    : 'https://market.onestreamrpg.com';

    return (
        <div>
            <a href={`https://id.twitch.tv/oauth2/authorize?response_type=token&client_id=2cmepj3fwk1eo2ptx15w4n7kx0mb18&redirect_uri=${redirectUri}&scope=channel%3Abot+chat%3Aedit+chat%3Aread+user%3Abot+user%3Aread%3Achat+user%3Awrite%3Achat&state=c3ab8aa609ea11e793ae92361f002671`} className="twitch-btn">
                <img src="https://upload.wikimedia.org/wikipedia/commons/4/41/Twitch_Glitch_Logo_White.svg" alt="Twitch Logo" className="twitch-logo" />
                Connect with Twitch
            </a>
        </div>
    );
}

export default Home;