import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { showNotification } from '../components/Utils';

const useAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("twitchToken") && 
                       localStorage.getItem("twitchData") && 
                       localStorage.getItem("characterId");

    const protectedPaths = ["/addListing", "/history", "/listings/me"];
    const isProtectedPath = protectedPaths.includes(location.pathname);

    if (!isLoggedIn && isProtectedPath) {
      showNotification("You need to login to use the market.", function() {
        navigate("/");
      });
    }
  }, [navigate, location]);
};

export default useAuth;
