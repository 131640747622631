import React, { createContext, useState, useContext, useEffect } from 'react';

const ToastContext = createContext();

let globalSetToastMessage;

export const ToastProvider = ({ children }) => {
  const [toastMessage, setToastMessage] = useState(() => {
    // Load initial state from localStorage
    return localStorage.getItem('toastMessage');
  });

  globalSetToastMessage = (message) => {
    setToastMessage(message);
    // Save message to localStorage
    localStorage.setItem('toastMessage', message);
  };

  useEffect(() => {
    // Clear toastMessage from localStorage when it's shown
    if (toastMessage === null) {
      localStorage.removeItem('toastMessage');
    }
  }, [toastMessage]);

  return (
    <ToastContext.Provider value={{ toastMessage, setToastMessage: globalSetToastMessage }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);

export const setGlobalToastMessage = (message, delayedCode) => {
  if (globalSetToastMessage) {
    globalSetToastMessage(message);
    if (delayedCode) {
      delayedCode();
    }
  }
};