import React, { useMemo, useState } from 'react';
import { formatItemAmount, formatItemStyle } from './Utils';

const Inventory = ({ inventory, onItemSelect, loadedImages, isActive }) => {

  const handleClick = (index) => {
    onItemSelect(inventory[index], index);
  };

  return (
    <div className="inventory-background">
      {[...Array(5)].map((_, rowIndex) => (
        <div key={rowIndex} className="inventory-row">
          {[...Array(5)].map((_, colIndex) => {
            const index = rowIndex * 5 + colIndex;
            const item = inventory[index];
            if (!item || item.name === 'empty') {
              return <div key={colIndex} className="inventory-cell ui-sortable"></div>;
            }
            const itemStyle = formatItemStyle(item, loadedImages);
            return (
              <div key={colIndex} className="inventory-cell ui-sortable">
                <div
                  onClick={() => handleClick(index)}
                  className={`${isActive === index ? 'inventory-item active' : 'inventory-item'}`}
                  style={itemStyle}
                  title=""
                >
                  {item.amount > 0 && formatItemAmount(item.amount)}
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default React.memo(Inventory);
