import React, { useState } from 'react';
import { formatItemAmount, formatItemStyle } from './Utils';

const Stash = ({ stash, onItemSelect, loadedImages, isActive }) => {
  const itemsPerPage = 50;
  const totalPages = Math.ceil(stash.length / itemsPerPage);
  const maxPages = 10; // Maximum number of pagination buttons
  const [currentPage, setCurrentPage] = useState(1);

  const handleClick = (index) => {
    onItemSelect(stash[index], index);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPagination = () => (
    <div className="stash-pagination">
      {[...Array(maxPages)].map((_, pageIndex) => {
        const page = pageIndex + 1;
        const isActivePage = page === currentPage;
        const isDisabled = page > totalPages;
        const firstItemIndex = (page - 1) * itemsPerPage;
        const firstItem = stash[firstItemIndex];
        const shouldShowImage = !isDisabled && firstItem && firstItem.name !== 'empty';
        
        return (
          <div
            key={pageIndex}
            className={`page-button ui-droppable ${isActivePage ? 'active' : ''} ${isDisabled ? 'disabled' : ''}`}
            data-page={page}
            onClick={() => !isDisabled && handlePageChange(page)}
            title={isDisabled ? 'Buy more stash pages on the shop.' : ''}
          >
            {shouldShowImage ? (
              <div className="page-image" style={formatItemStyle(firstItem, loadedImages)}></div>
            ) : (
              page
            )}
          </div>
        );
      })}
    </div>
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentPageItems = stash.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div>
      <div className="inventory-background">
        {[...Array(totalPages)].map((_, pageIndex) => (
          <div
            key={pageIndex}
            className={`inventory-table stash-inventory-${pageIndex + 1}`}
            style={{ display: currentPage === pageIndex + 1 ? 'block' : 'none' }}
          >
            {[...Array(5)].map((_, rowIndex) => (
              <div key={rowIndex} className="inventory-row">
                {[...Array(10)].map((_, colIndex) => {
                  const index = rowIndex * 10 + colIndex;
                  const item = stash[startIndex + index];
                  if (!item || item.name === 'empty') {
                    return <div key={colIndex} className="inventory-cell ui-sortable"></div>;
                  }
                  const itemStyle = formatItemStyle(item, loadedImages);
                  return (
                    <div key={colIndex} className="inventory-cell ui-sortable">
                      <div
                        onClick={() => handleClick(startIndex + index)}
                        className={`${isActive === startIndex + index ? 'inventory-item active' : 'inventory-item'}`}
                        style={itemStyle}
                        title=""
                      >
                        {item.amount > 0 && formatItemAmount(item.amount)}
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        ))}
      </div>
      {renderPagination()}
    </div>
  );
};

export default React.memo(Stash);