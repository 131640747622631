import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { NumericFormat } from 'react-number-format';
import $ from 'jquery';

const FilterModal = React.forwardRef(({ show, onClose, onSearch }, ref) => {
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [useMinPrice, setUseMinPrice] = useState(false);
  const [useMaxPrice, setUseMaxPrice] = useState(false);

  const [kind, setKind] = useState('Any');
  const kindSelectRef = useRef(null);

  const [shimmering, setShimmering] = useState('Any');
  const shimmeringSelectRef = useRef(null);

  const [armorRarity, setArmorRarity] = useState('');
  const armorRaritySelectRef = useRef(null);

  const [damageRarity, setDamageRarity] = useState('');
  const damageRaritySelectRef = useRef(null);

  const modalContentRef = useRef(null);

  useImperativeHandle(ref, () => ({
      setFilters: (filters) => {
        setMinPrice(filters.minPrice);
        setMaxPrice(filters.maxPrice);
        setKind(filters.kind || 'Any');
        setShimmering(filters.shimmering || 'Any');
        setArmorRarity(filters.minArmorBonus || '');
        setDamageRarity(filters.minDamageBonus || '');
      },
      resetFilters: () => {
          setMinPrice('');
          setMaxPrice('');
          setUseMinPrice(false);
          setUseMaxPrice(false);
          setKind('Any');
          setShimmering('Any');
          setArmorRarity('');
          setDamageRarity('');
      },
      resetSpecificFilter: (filterType) => {
        // Logic to reset specific filters based on filterType
        switch(filterType) {
            case 'minPrice':
                setMinPrice('');
                setUseMinPrice(false);
                break;
            case 'maxPrice':
                setMaxPrice('');
                setUseMaxPrice(false);
                break;
            case 'kind':
                setKind('Any');  // Resets to default value
                break;
            case 'shimmering':
                setShimmering('Any');  // Resets to default value
                break;
            case 'armorBonus':
                setArmorRarity('');  // Resets to no selection or default
                break;
            case 'damageBonus':
                setDamageRarity('');  // Resets to no selection or default
                break;
            default:
                console.warn(`Filter type ${filterType} not recognized.`);
        }
    }
  }));

  const handleSearch = () => {
    onSearch({
      minPrice: useMinPrice ? minPrice : '',
      maxPrice: useMaxPrice ? maxPrice : '',
      kind: kind === 'Any' ? '' : kind,
      shimmering: shimmering === 'Any' ? '' : shimmering,
      minArmorBonus: armorRarity === 'Any' ? '' : armorRarity,
      maxArmorBonus: armorRarity === 'Any' ? '' : armorRarity,
      minDamageBonus: damageRarity === 'Any' ? '' : damageRarity,
      maxDamageBonus: damageRarity === 'Any' ? '' : damageRarity
    });
    onClose();
  };

  const handleClickOutside = (event) => {
    if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
      onClose();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  const handleCustomDropdownClick = (event) => {
    const dropdownItems = $('.rpgui-dropdown-imp li').toArray();
    const clickedItemIndex = dropdownItems.indexOf(event.target);
    if (clickedItemIndex !== -1) {
      const selectElement = $(event.target).parent().prev().prev('select')[0];
      if (selectElement) {
        var event = new Event('change', {
          bubbles: true,
          cancelable: true
        });
        selectElement.dispatchEvent(event);
      }
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleKeyDown);

      const initializeRPGUIDropdown = (ref) => {
        if (ref.current && !ref.current.initialized) {
          ref.current.initialized = true;
          if (window.RPGUI) {
            window.RPGUI.create(ref.current, "dropdown");
            ref.current.className = '';
          }
        }
      };

      initializeRPGUIDropdown(kindSelectRef);
      initializeRPGUIDropdown(shimmeringSelectRef);
      initializeRPGUIDropdown(armorRaritySelectRef);
      initializeRPGUIDropdown(damageRaritySelectRef);

      $(document).on('click', '.rpgui-dropdown-imp li', handleCustomDropdownClick);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
      $(document).off('click', '.rpgui-dropdown-imp li', handleCustomDropdownClick);
    };
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content rpgui-container framed-golden-2" ref={modalContentRef}>
        <span className="close-button" onClick={onClose}>&times;</span>
        <h2>Advanced Search</h2>
        <div className="modal-body">
          <div className="filter-option">
            <input
              type="checkbox"
              checked={useMinPrice}
              onChange={(e) => setUseMinPrice(e.target.checked)}
            />
            <label><p>Minimum Price</p></label>
          </div>
          <NumericFormat
            value={minPrice}
            onValueChange={(values) => setMinPrice(values.value)}
            disabled={!useMinPrice}
            thousandSeparator={true}
            allowNegative={false}
            customInput="input"
          />
          <div className="filter-option">
            <input
              type="checkbox"
              checked={useMaxPrice}
              onChange={(e) => setUseMaxPrice(e.target.checked)}
            />
            <label><p>Maximum Price</p></label>
          </div>
          <NumericFormat
            value={maxPrice}
            onValueChange={(values) => setMaxPrice(values.value)}
            disabled={!useMaxPrice}
            thousandSeparator={true}
            allowNegative={false}
            customInput="input"
          />
          <div className="filter-option">
            <label><p>Kind</p></label>
          </div>
          <select ref={kindSelectRef} className="rpgui-dropdown" value={kind} onChange={(e) => setKind(e.target.value)}>
            <option value="Any">Any</option>
            <option value="weapon">Weapon</option>
            <option value="helmet">Helmet</option>
            <option value="chest">Chest</option>
            <option value="plating">Plating</option>
            <option value="pants">Pants</option>
            <option value="fabric">Fabric</option>
            <option value="fishingItem">Fishing Item</option>
            <option value="fishingRod">Fishing Rod</option>
            <option value="bait">Bait</option>
            <option value="component">Component</option>
          </select>
          <div className="filter-option">
            <label><p>Shimmering</p></label>
          </div>
          <select ref={shimmeringSelectRef} className="rpgui-dropdown" value={shimmering} onChange={(e) => setShimmering(e.target.value)}>
            <option value="Any">Any</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
          <div className="filter-option">
            <label><p>Armor Rarity</p></label>
          </div>
          <select ref={armorRaritySelectRef} className="rpgui-dropdown" value={armorRarity} onChange={(e) => setArmorRarity(e.target.value)}>
            <option value="Any">Any</option>
            <option value="0.4">Extraordinary</option>
            <option value="0.6">Epic</option>
            <option value="0.8">Legendary</option>
            <option value="1.0">Forbidden</option>
          </select>
          <div className="filter-option">
            <label><p>Damage Rarity</p></label>
          </div>
          <select ref={damageRaritySelectRef} className="rpgui-dropdown" value={damageRarity} onChange={(e) => setDamageRarity(e.target.value)}>
            <option value="">Any</option>
            <option value="0.4">Extraordinary</option>
            <option value="0.6">Epic</option>
            <option value="0.8">Legendary</option>
            <option value="1.0">Forbidden</option>
          </select>
        </div>
        <div className="modal-footer">
          <button className="rpgui-button" onClick={handleSearch}><p>Search</p></button>
        </div>
      </div>
    </div>
  );
});

export default FilterModal;