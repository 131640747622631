import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useImageCache } from '../components/ImageCacheContext';
import { formatItemStyle, formatNumber, generateFixedTooltip, generateItemTooltip } from '../components/Utils';
import ActionButton from "../components/ActionButton";
import ResponsivePagination from 'react-responsive-pagination';
import { dropEllipsis } from 'react-responsive-pagination/narrowBehaviour';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { isMobile } from 'react-device-detect';
import config from '../config';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/tooltip';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/tooltip.css';

const Listing = () => {
  const { marketId } = useParams();
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [listing, setListing] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [submitting, setSubmitting] = useState(false);
  const [amount, setAmount] = useState(1);
  const navigate = useNavigate();
  const { loadedImages, preloadImages } = useImageCache();
  const [sortField, setSortField] = useState('price');
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    const fetchListing = () => {
      const url = `${config.serverURL}/marketListing/${marketId}`;
      const twitchData = JSON.parse(localStorage.getItem("twitchData"));
      const login = twitchData?.login;
      fetch(url)
        .then(res => res.json())
        .then(data => {
          data.isOwner = login === data.username;
          setListing(data);
          setAmount(data.amount);
          preloadImages([data.item]);
        })
        .catch(error => {
          navigate(`/listings`); // Navigate on error
          console.error('Failed to fetch listing:', error);
        });
    };

    fetchListing();
  }, []);

  useEffect(() => {
    $(document).tooltip({
      track: true,
      content: function() {
        const element = $(this);
        const item = element.attr('title'); // Assuming data-item is set on the element
        if (item) {
          return item;
        }
        return '';
      }
    });
    return () => {
      $(document).tooltip('destroy');
    };
  }, []);

  useEffect(() => {
    if (listing){
      fetchListings();
    }
  }, [listing, currentPage]);

  const incrementAmount = () => {
    if (amount < listing.amount) {
      setAmount(amount + 1);
    }
  };

  const decrementAmount = () => {
    if (amount > 1) {
      setAmount(amount - 1);
    }
  };

  const handleAmountChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 1 && value <= listing.amount) {
      setAmount(value);
    }
  };

  const renderSortArrow = (field) => {
    if (sortField === field) {
      return sortOrder === 'asc' ? ' ↓' : ' ↑';
    }
    return '';
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const fetchListings = (
    page = currentPage,
    sortFieldParam = sortField,
    sortOrderParam = sortOrder
  ) => {
    setLoading(true);
    
    const urlParams = new URLSearchParams({
      page,
      limit: itemsPerPage,
      name: listing.item.name,
      sortBy: sortFieldParam,
      order: sortOrderParam,
    });

    const url = `${config.serverURL}/marketListings?${urlParams.toString()}`;

    fetch(url)
      .then(res => res.json())
      .then(data => {
        preloadImages(data.listings.map(listing => listing.item));
        setListings(data.listings);
        setTotalPages(data.totalPages);
        setLoading(false);
      })
      .catch(error => {
        console.error('Failed to fetch listings:', error);
        setLoading(false);
      });
  };

  const handleSort = (field) => {
    const newSortOrder = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(newSortOrder);
    setCurrentPage(1); // Reset to the first page on sort change
    fetchListings(1, field, newSortOrder);
  };

  if (!listing) {
    return <div style={{ textAlign: 'center', color: 'white' }}>Loading...</div>;
  }

  return (
    <div>
      <div style={{ height: '100%', margin: '0 auto' }}>
        <h3>Listing</h3>
        <div className="rpgui-container framed-golden add-listing-container" style={{ height: '100%' }}>
          <div className='listing-item'>
            <div className="listing-item-form">
              <label htmlFor='amount'>Amount</label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input 
                  id="customAmountInput"
                  className="custom-amount"
                  type="number" 
                  name='amount' 
                  value={amount} 
                  min="1" 
                  max={listing.amount || 1}
                  onChange={handleAmountChange}
                  disabled={listing.isOwner || (listing.amount <= 1)}
                />
                <div className="spin-buttons">
                  <button 
                    type="button" 
                    className="spin-button up" 
                    onClick={incrementAmount}
                    disabled={listing.isOwner || (listing.amount <= 1) || amount >= listing.amount}
                  >
                    ▲
                  </button>
                  <button 
                    type="button" 
                    className="spin-button down" 
                    onClick={decrementAmount}
                    disabled={listing.isOwner || (listing.amount <= 1) || amount <= 1}
                  >
                    ▼
                  </button>
                </div>
              </div>
              <label htmlFor='price'>Price</label>
              <input 
                type="text" 
                name='price' 
                value={formatNumber((listing.price || 0) * amount)} 
                disabled 
              />
              <ActionButton listing={listing} skipInput={true} amount={amount} disabled={submitting} className='remove-listing' reload={false}/>
            </div>
            {listing.item && generateFixedTooltip({
              item: listing.item,
              itemStyle: formatItemStyle(listing.item, loadedImages, true),
              extraClass: "listing-tooltip"
            })}
          </div>
        </div>
      </div>
      <div style={{ width: '100%', marginTop: '30px' }}>
        <h3>Similar Listings</h3>
        <div className="rpgui-container framed-golden listing-table-container">
          <Table>
            <Thead>
              <Tr>
                <Th>{isMobile ? "Picture" : ""}</Th>
                <Th onClick={() => handleSort('amount')}>
                  <a href="#" onClick={(e) => { e.preventDefault(); handleSort('amount'); }}>
                    Amount{renderSortArrow('amount')}
                  </a>
                </Th>
                <Th onClick={() => handleSort('price')}>
                  <a href="#" onClick={(e) => { e.preventDefault(); handleSort('price'); }}>
                    Price{renderSortArrow('price')}
                  </a>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading ? <Tr><Td colSpan="6">Loading...</Td></Tr> : listings.map(listing => (
                <Tr key={listing.marketId}>
                  <Td title={generateItemTooltip(listing.item, loadedImages)}>
                    <div className="listing-item-image" style={formatItemStyle(listing.item, loadedImages, true)}></div>
                  </Td>
                  <Td><div className="osrpg-responsive-content">{formatNumber(listing.amount)}</div></Td>
                  <Td><div className="osrpg-responsive-content">{formatNumber(listing.price)}</div></Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
        <ResponsivePagination
          total={totalPages}
          current={currentPage}
          onPageChange={handlePageChange}
          narrowBehaviour={dropEllipsis}
          pageLinkClassName="osrpg-page-link"
        />
      </div>
    </div>
  );
}

export default Listing;