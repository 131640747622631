import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import ConfirmationModal from './ConfirmationModal';
import { showNotification, formatNumber } from './Utils';
import config from '../config';

const ActionButton = ({ listing, amount = 1, skipInput = false, disabled = false, className = "", reload = true }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [purchaseAmount, setPurchaseAmount] = useState(amount);
  const [modalDescription, setModalDescription] = useState(
    listing.isOwner
      ? "Are you sure you want to remove this listing?"
      : `Are you sure you want to buy ${amount > 1 ? amount + ' ' + listing.item.name : listing.item.name} for ${formatNumber(listing.price * amount)}?`
  );

  useEffect(() => {
    setPurchaseAmount(amount);
    updateDescription(amount);
  }, [amount]);

  const updateDescription = (newAmount) => {
    setModalDescription(
      listing.isOwner
        ? "Are you sure you want to remove this listing?"
        : `Are you sure you want to buy ${newAmount > 0 ? newAmount + ' ' + listing.item.name : listing.item.name} for ${formatNumber(listing.price * newAmount)}?`
    );
  };

  const buyListing = async (buyAmount) => {
    const url = `${config.serverURL}/buyMarketItem`;
    const accessToken = localStorage.getItem("twitchToken");

    const payload = {
      jwt: null,
      accessToken: accessToken,
      marketId: listing.marketId,
      amount: buyAmount
    };

    setSubmitting(true);
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });

      if (response.ok) {
        const responseData = await response.json();
        showNotification(
          `You successfully bought ${listing.amount > 1 ? `${buyAmount} ${listing.item.name} for ${listing.price * buyAmount}` : `${listing.item.name} for ${listing.price}`}`,
          () => {
            if (reload) {
              window.location.reload();
            } else {
              navigate(`/history`);
            }
          }
        );
      } else {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || 'Error buying item, please try again.');
      }
    } catch (error) {
      showNotification(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const deleteListing = async () => {
    const url = `${config.serverURL}/deleteMarketOrder`;
    const accessToken = localStorage.getItem("twitchToken");

    const payload = {
      jwt: null,
      accessToken: accessToken,
      marketId: listing.marketId
    };

    setSubmitting(true);
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });

      if (response.ok) {
        showNotification(
          'Listing deleted successfully!',
          () => {
            if (reload) {
              window.location.reload();
            } else {
              navigate(`/listings`);
            }
          }
        );
      } else {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || 'Failed to delete listing.');
      }
    } catch (error) {
      showNotification(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleConfirm = (buyAmount) => {
    if (listing.isOwner) {
      deleteListing();
    } else {
      buyListing(buyAmount);
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <button className={`buyButton rpgui-button ${className}`} onClick={handleClick} disabled={disabled}>
        <p>{listing.isOwner ? "Remove" : "Buy"}</p>
      </button>
      <ConfirmationModal
        isOpen={isModalOpen}
        title={listing.isOwner ? "Confirm Removal" : "Confirm Purchase"}
        description={modalDescription}
        onConfirm={handleConfirm}
        onClose={handleCancel}
        disabled={submitting}
        isPurchase={!listing.isOwner}
        initialAmount={purchaseAmount}
        updateDescription={updateDescription}
        skipInput={skipInput}
      />
    </>
  );
};

export default ActionButton;