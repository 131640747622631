const forceDevMode = false;  // Set this to true to force development mode

const config = {
  serverURL: forceDevMode || process.env.NODE_ENV === 'development'
    ? "https://germany.pauledevelopment.com:8052"
    : "https://germany.pauledevelopment.com:8051",
  extraordinaryColor: '#2270AF',
  epicColor: '#7C337B',
  legendaryColor: '#CCB333',
  forbiddenColor: '#4B4A56'
};

export default config;
