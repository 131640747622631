import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { generateItemTooltip, formatItemStyle, formatDate } from '../components/Utils';
import { useImageCache } from '../components/ImageCacheContext';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/tooltip';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/tooltip.css';
import ResponsivePagination from 'react-responsive-pagination';
import { dropEllipsis } from 'react-responsive-pagination/narrowBehaviour';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { isMobile, isTablet, isBrowser } from 'react-device-detect';
import config from '../config';

const History = ({ me = false }) => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [isManualFetch, setIsManualFetch] = useState(false);
  const { loadedImages, preloadImages } = useImageCache();

  // Debouncing searchTerm
  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm !== debouncedSearchTerm) {
        setDebouncedSearchTerm(searchTerm);
      }
    }, 2000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (!isManualFetch) {
      fetchHistory(debouncedSearchTerm);
    }
    setIsManualFetch(false); // Reset the manual fetch flag after handling
  }, [debouncedSearchTerm, currentPage, itemsPerPage]);

  const fetchHistory = (query = debouncedSearchTerm) => {
    setLoading(true);
    const encodedSearchTerm = encodeURIComponent(query);
    const characterId = me ? localStorage.getItem('characterId') : '';
    const url = `${config.serverURL}/marketLog`;

    const body = {
      page: currentPage,
      limit: itemsPerPage,
      name: encodedSearchTerm,
      accessToken: localStorage.getItem('twitchToken')
    };

    if (characterId) {
      body.characterId = characterId;
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then(res => res.json())
      .then(data => {
        preloadImages(data.history.map(hist => hist.data.item));
        setHistory(data.history);
        setTotalPages(data.totalPages);
        setLoading(false);
      })
      .catch(error => {
        console.error('Failed to fetch listings:', error);
        setLoading(false);
      });
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setIsManualFetch(true); // Set the manual fetch flag
    setCurrentPage(1);
    fetchHistory(searchTerm);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setCurrentPage(1);
    setIsManualFetch(true); // Set the manual fetch flag
    fetchHistory('');
  };

  const formatNumber = (num) => {
    if (num && num !== null)
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else
      return num;
  };

  useEffect(() => {
    $(document).tooltip({
      track: true,
      content: function() {
        const element = $(this);
        const item = element.attr('title'); // Assuming data-item is set on the element
        if (item) {
          return item;
        }
        return '';
      }
    });
    // Clean up the tooltip when the component unmounts
    return () => {
      $(document).tooltip('destroy');
    };
  }, []);

  return (
    <div>
      <div className='table__container'>
        
        <form onSubmit={handleSearch} className="listing-form">
          <input 
            type="text" 
            placeholder="Search by name..." 
            value={searchTerm} 
            onChange={e => setSearchTerm(e.target.value)}
          />
          <div className="button-container">
            <button type="submit" className="rpgui-button"><p>Search</p></button>
            <button type="button" className="rpgui-button" onClick={clearSearch}><p>Clear</p></button>
          </div>
        </form>

        <div className="rpgui-container framed-golden listing-table-container">
          <Table>
            <Thead>
              <Tr>
                <Th>{isMobile ? "Picture" : ""}</Th>
                <Th>Title</Th>
                <Th>Amount</Th>
                <Th>Date</Th>
                <Th>Action</Th>
                <Th>{isMobile ? "Unit" : "Unit Price"}</Th>
                <Th>{isMobile ? "Price" : "Total Price"}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading ? <Tr><Td colSpan="7">Loading...</Td></Tr> : history.map(hist => (
                <Tr key={hist.data.marketId}>
                  <Td title={generateItemTooltip(hist.data.item, loadedImages)}>
                    <div className="listing-item-image" style={formatItemStyle(hist.data.item, loadedImages, true)}></div>
                  </Td>
                  <Td><div className="osrpg-responsive-content">{hist.data.item.name}</div></Td>
                  <Td><div className="osrpg-responsive-content">{formatNumber(hist.data.amount)}</div></Td>
                  <Td><div className="osrpg-responsive-content">{formatDate(hist.timestamp)}</div></Td>
                  <Td><div className="osrpg-responsive-content">{hist.action}</div></Td>
                  <Td><div className="osrpg-responsive-content">{formatNumber(hist.data.price)}</div></Td>
                  <Td><div className="osrpg-responsive-content">{formatNumber(hist.data.price*hist.data.amount)}</div></Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
        <ResponsivePagination
          total={totalPages}
          current={currentPage}
          onPageChange={handlePageChange}
          narrowBehaviour={dropEllipsis}
          pageLinkClassName="osrpg-page-link"
        />
      </div>
    </div>
  );
}

export default History;