import React, { createContext, useContext, useState } from 'react';

const ImageCacheContext = createContext({});

export const useImageCache = () => useContext(ImageCacheContext);

export const ImageCacheProvider = ({ children }) => {
    const [loadedImages, setLoadedImages] = useState({});

    const preloadImages = (items) => {
        let imagesToLoad = items.length * 2; // Each item has two images to load

        items.forEach(item => {
            const baseImageUrl = "https://onestreamrpg.com/extension/images/items/";
            if (item.name === 'empty') {
                // Directly set default image for 'empty' items
                setLoadedImages(prev => ({
                    ...prev,
                    [item.name]: `${baseImageUrl}default.png`
                }));
                imagesToLoad -= 2;
                return;
            }

            const imageName = item.name.replace(/\s/g, '');
            const imageExtension = item.kind.toLowerCase() === 'gem' ? '.gif' : '.png';
            const imageUrl = `${baseImageUrl}${imageName}${imageExtension}`;

            const loadImage = (url, key) => {
                const img = new Image();
                img.onload = () => {
                    setLoadedImages(prev => ({ ...prev, [key]: url }));
                    imagesToLoad--;
                };
                img.onerror = () => {
                    setLoadedImages(prev => ({ ...prev, [key]: `${baseImageUrl}default.png` }));
                    imagesToLoad--;
                };
                img.src = url;
            };

            loadImage(imageUrl, imageName);
            if (item.kind.toLowerCase() !== 'gem') {
                const shimmeringImageUrl = `${baseImageUrl}${imageName}_shimmering.png`;
                loadImage(shimmeringImageUrl, `${imageName}_shimmering`);
            }            
        });

        if (imagesToLoad === 0) {
            console.log("No images needed to be loaded or all were 'empty'.");
        }
    };

    return (
        <ImageCacheContext.Provider value={{ loadedImages, preloadImages }}>
            {children}
        </ImageCacheContext.Provider>
    );
};
