import React, { forwardRef, useMemo } from 'react';

const ItemSelect = forwardRef(({ inventory, stash, activeInventoryIndex, activeStashIndex, handleItemSelect }, ref) => {
  const options = useMemo(() => {
    const inventoryOptions = inventory
      .map((item, index) => ({ item, index }))
      .filter(({ item }) => item.name !== "empty" && item.tradable !== false)
      .map(({ item, index }) => (
        <option key={`inventory-${index}`} value={`inventory-${index}`} data-type="inventory" data-index={index}>
          {item.name}
        </option>
      ));

    const stashOptions = stash
      .map((item, index) => ({ item, index }))
      .filter(({ item }) => item.name !== "empty" && item.tradable !== false)
      .map(({ item, index }) => (
        <option key={`stash-${index}`} value={`stash-${index}`} data-type="stash" data-index={index}>
          {item.name}
        </option>
      ));

    return [...inventoryOptions, ...stashOptions];
  }, [inventory, stash]);

  const selectedValue = useMemo(() => {
    if (activeInventoryIndex !== null) {
      return `inventory-${activeInventoryIndex}`;
    }
    if (activeStashIndex !== null) {
      return `stash-${activeStashIndex}`;
    }
    return "";
  }, [activeInventoryIndex, activeStashIndex]);

  return (
    <select className="rpgui-dropdown" ref={ref} onChange={handleItemSelect} value={selectedValue}>
      <option value="" disabled>Select an item</option>
      {options}
    </select>
  );
});

export default React.memo(ItemSelect);