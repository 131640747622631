import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, matchPath } from "react-router-dom";
import Nav from "./components/Nav";
import routesConfig from "./routesConfig";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-pagination/themes/classic.css';
import { ToastProvider, useToast } from './components/ToastContext';
import useAuth from './hooks/useAuth'; // Import the custom hook
import { isMobile, isTablet } from 'react-device-detect';

function AppContent() {
  useAuth(); // Use the custom hook to check authentication

  const location = useLocation();
  const { toastMessage, setToastMessage } = useToast();
  const [shouldShowToast, setShouldShowToast] = useState(false);

  const env = process.env.NODE_ENV;
  const appTitle = env === 'development' ? "OSRPG Test Market" : "OSRPG Market";
  const navTitle = env === 'development' ? "OneStreamRPG Test Market" : "OneStreamRPG Market";

  useEffect(() => {
    document.title = appTitle;
  }, [appTitle]);

  useEffect(() => {
    const toastPosition = isMobile || isTablet ? "bottom-center" : "bottom-right";
    if (shouldShowToast && toastMessage) {
      toast(toastMessage, {
        position: toastPosition,
      });
      setToastMessage(null); // Clear the message after showing the toast
      setShouldShowToast(false); // Reset the flag
    }
  }, [shouldShowToast, toastMessage, setToastMessage]);

  useEffect(() => {
    if (toastMessage) {
      setShouldShowToast(true);
    }
  }, [location]);

  const currentRoute = routesConfig.find(route =>
    matchPath(route.path, location.pathname)
  );

  const subtitle = currentRoute ? currentRoute.subtitle : "Sell your precious items";

  return (
    <div className="container">
      <div className="inner rpgui-container framed" style={{ position: 'relative' }}>
        <Nav title={navTitle} subtitle={subtitle} />
        <Routes>
          {routesConfig.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Routes>
      </div>
      <ToastContainer 
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        toastClassName="rpgui-container framed-golden-2 notification"
      />
    </div>
  );
}

function App() {
  return (
    <ToastProvider>
      <AppContent />
    </ToastProvider>
  );
}

export default App;