import Home from "./pages/Home";
import Listing from "./pages/Listing";
import Listings from "./pages/Listings";
import AddListing from "./pages/AddListing";
import History from "./pages/History";
import { ImageCacheProvider } from "./components/ImageCacheContext";
import RedirectToHome from "./components/RedirectToHome";

const routesConfig = [
  { path: "/", element: <Home />, subtitle: "Welcome to the Market" },
  { 
    path: "/listing/:marketId", 
    element: (
      <ImageCacheProvider>
        <Listing />
      </ImageCacheProvider>
    ), 
    subtitle: "View Listing Details" 
  },
  { 
    path: "/listings", 
    element: (
      <ImageCacheProvider>
        <Listings />
      </ImageCacheProvider>
    ), 
    subtitle: "Browse All Listings" 
  },
  { 
    path: "/listings/add", 
    element: (
      <ImageCacheProvider>
        <AddListing />
      </ImageCacheProvider>
    ), 
    subtitle: "Add a New Listing" 
  },
  { 
    path: "/listings/me", 
    element: (
      <ImageCacheProvider>
        <Listings me={true} />
      </ImageCacheProvider>
    ), 
    subtitle: "My Listings" 
  },
  { 
    path: "/history", 
    element: (
      <ImageCacheProvider>
        <History />
      </ImageCacheProvider>
    ), 
    subtitle: "History" 
  },
  // Wildcard route for non-existent paths
  { 
    path: "*", 
    element: <RedirectToHome /> 
  },
];

export default routesConfig;