import React, { useState, useEffect } from 'react';

const ConfirmationModal = ({ isOpen, title, description, onClose, onConfirm, isPurchase, initialAmount, updateDescription, skipInput = false }) => {
  const [amount, setAmount] = useState(initialAmount);

  const handleAmountChange = (e) => {
    const newAmount = e.target.value;
    setAmount(newAmount);
    updateDescription(newAmount);
  };

  const handleConfirmClick = () => {
    onConfirm(amount);
  };

  const incrementAmount = () => {
    if (amount < initialAmount) {
      let newAmount = amount + 1;
      setAmount(newAmount);
      updateDescription(newAmount);
    }
  };

  const decrementAmount = () => {
    if (amount > 1) {
      let newAmount = amount - 1;
      setAmount(amount - 1);
      updateDescription(newAmount);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="confirmation-modal rpgui-container framed-golden-2 confirmation-modal">
      <h2>{title}</h2>
      <p>{description}</p>
      {isPurchase && !skipInput && initialAmount > 1 && (
        <div>
          <label style={{ display: 'inline-block' }}>
            Amount:
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input type="number" className="custom-amount" value={amount} onChange={handleAmountChange} min="1" />
            </div>
          </label>
        </div>
      )}
      <div className="confirmation-buttons">
        <button className="rpgui-button" onClick={handleConfirmClick} style={{ marginRight: '10px' }}><p>Yes</p></button>
        <button className="rpgui-button" onClick={onClose}><p>No</p></button>
      </div>
    </div>
  );
}

export default ConfirmationModal;